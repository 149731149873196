import React, { lazy, Suspense } from 'react';
import Loading from '@/components/PageLoading';

function LazyComponent(importFunc: any) {
  const LazyComp = lazy(importFunc);
  return (
    <Suspense fallback={<Loading />}>
      <LazyComp />
    </Suspense>
  );
}

export default LazyComponent;
