import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';
import axios from 'axios';
import { store } from '@/store';
import { currentEnv, proxyUrl } from '@/constant/url';
import { cookie } from '@/utils/cookie';
import { COOKIE_TOKEN_NAME } from '@/constant';
const UNAUTHORIZED_STATUS_CODE = [401, 440];
const NO_AUTH_STATUS_CODE = [403];
console.log(currentEnv, 'currentEnv');

class Request {
  private request(opts: {}) {
    const instance: AxiosInstance = axios.create({
      baseURL: currentEnv === 'dev' ? proxyUrl : '',
      timeout: 80000,
      headers: { 'content-type': 'application/json' },
      withCredentials: true,
    });
    this.setInterceptors(instance);
    return instance(opts);
  }
  //拦截器
  private setInterceptors(instance: AxiosInstance) {
    const accessToken = cookie.get(COOKIE_TOKEN_NAME);
    //请求拦截器
    instance.interceptors.request.use((config: any) => {
      config.headers = {
        ...config.headers,
        Regulator:
          sessionStorage.getItem('currentRegulator') ||
          store.getState().app.currentRegulator,
        Language: store.getState().app.lang,
        timezone: new Date().getTimezoneOffset() / -60, // 时区偏移
        Authorization: accessToken || '',
      };
      return config;
    });
    //响应拦截器
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(res.data);
        }
      },
      err => {
        console.log(err, 'err');
        const resStatus = err.response?.status || 0;
        if (UNAUTHORIZED_STATUS_CODE.includes(resStatus)) {
          // 登录失效
          cookie.delete(COOKIE_TOKEN_NAME, '/');
          message.warning('The session is invalid. Please login again');
          window.location.href = '/login';
        } else if (NO_AUTH_STATUS_CODE.includes(resStatus)) {
          // 没有权限
          window.location.href = '/403';
        } else {
          if (err?.response?.data?.message) {
            message.error(err?.response?.data?.message);
          } else {
            message.error(
              `Brower status: ${err?.response?.status},The Internet Error,Please Try Again!`,
            );
          }
        }
        return Promise.reject(err);
      },
    );
  }
  //封装get请求
  public get(url: string, params?: any) {
    return this.request({
      url: url,
      method: 'get',
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
      params,
    });
  }
  //封装post请求
  public post(url: string, data?: any) {
    return this.request({
      url: url,
      method: 'post',
      data,
    });
  }
  //封装get请求
  public rcGet(url: string, token: string, params?: any) {
    return this.request({
      url: url,
      method: 'get',
      headers: {
        'RC-Authorization': token,
      },
      params,
    });
  }

  public rcPost(url: string, token: string, data?: any) {
    return this.request({
      url: url,
      method: 'post',
      headers: {
        'RC-Authorization': token,
      },
      data,
    });
  }
}
export default Request;
