export const currentEnv = process.env.REACT_APP_ENV;
/**
 * @description request base url
 * capricorn
 * uranus
 * sagittarius
 * Tethys
 */
// target: 'https://sws-sagittarius-ex.crm-alpha.com',
// target: 'https://sws-uranus-ex.crm-alpha.com',
// target: 'https://sws-capricorn-ex.crm-alpha.com',
export const proxyUrl = 'https://sws-uranus-ex.crm-alpha.com';
