import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Result } from 'antd';

function ErrorBoundary() {
  const error = useRouteError();
  const errorText = String(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops!</h1>
        <h2>{error.status}</h2>
        <p>{error.statusText}</p>
        {error.data?.message && <p>{error.data.message}</p>}
      </div>
    );
  } else if (
    errorText.includes('Loading') &&
    errorText.includes('chunk') &&
    errorText.includes('failed')
  ) {
    window.location.reload();
    return null;
  } else {
    return (
      <Result
        status="error"
        title="Sorry,There is an error on this page"
        subTitle={errorText}
      />
    );
  }
}
export default ErrorBoundary;
