import { createModel } from '@rematch/core';
import {
  changeRegulatorData,
  getAllEnumsData,
} from '@/apis/app';
import type { RootModel } from '.';

const defaultCollapsed: boolean = window.innerWidth < 1200;

export const app = createModel<RootModel>()({
  state: {
    collapsed: defaultCollapsed,
    currentRegulator: 'asic',
    lang: sessionStorage.getItem('currentLang') || 'en',
    userInfo: {
      username: '',
      userId: '',
      phoneNumber:''
    },
    countryList: [], // public
    languageList: [], // public
    dataSourceMT: [], // public
    rcAuthToken:'',
    isFirstLogin: '',
  },
  reducers: {
    setCollapsed: (state, payload: boolean = false) => {
      return {
        ...state,
        collapsed: payload,
      };
    },
    setUserInfo: (state, payload: any) => {
      return {
        ...state,
        userInfo: payload,
      };
    },
    updateLang: (state, payload: string) => {
      sessionStorage.setItem('currentLang', payload);
      return {
        ...state,
        lang: payload,
      };
    },
    updateRegulator: (state, payload: string) => {
      sessionStorage.setItem('currentRegulator', payload);
      return {
        ...state,
        currentRegulator: payload,
      };
    },
    setCountryList: (state, payload: any) => {
      return {
        ...state,
        countryList: payload,
      };
    },
    setLanguageList: (state, payload: any) => {
      return {
        ...state,
        languageList: payload,
      };
    },
    setDataSourceMT: (state, payload: any) => {
      return {
        ...state,
        dataSourceMT: payload,
      };
    },
    setRcAuthToken: (state, payload: any) => {
      return {
        ...state,
        rcAuthToken: payload,
      };
    },
    setLoginStatus:(state,payload:any)=>{
      return{
        ...state,
        isFirstLogin:payload
      }
    }
  },
  effects: dispatch => ({
    async changeRegulator(val) {
      return new Promise((resolve, reject) => {
        changeRegulatorData(val)
          .then(res => {
            resolve(res);
            this.updateRegulator(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async getAllEnums() {
      try {
        let res: any = await getAllEnumsData();
        if (res.success) {
          const { data } = res;
          this.setCountryList(data.CountryEnum || []);
          this.setLanguageList(data.LocaleEnum || []);
          this.setDataSourceMT(data.DataSource_MT_Enum || []);
        }
      } catch (error) {}
    },
  }),
});
