import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider, message } from 'antd';
import Loading from '@/components/PageLoading';
// import Routes from './routes/index';
import routers from '@/routes';

import { store } from './store';
import { antdTheme } from '@/common/antdThemeConfig';

import '@/common/antdOverride.less';

message.config({
  maxCount: 1,
  top: 40,
});

function App() {
  useEffect(() => {
    store.dispatch.app.getAllEnums();
  }, []);

  return (
    <ConfigProvider theme={antdTheme}>
      <Provider store={store}>
        <RouterProvider router={routers} fallbackElement={<Loading />} />
      </Provider>
    </ConfigProvider>
  );
}

export default App;
