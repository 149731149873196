export const cookie = {
  get(keys) {
    let mat = new RegExp(`(^|[^a-z])${keys}=(.*?)(;|$)`, 'i').exec(document.cookie)
    return mat ? decodeURIComponent(mat[2]) : ''
  },
  set(name, value, expires?:any, path?:string, domain?:string, secure?:string) {
    let cookieText = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
    if (expires instanceof Date) {
      cookieText += `; expires=${expires.toString()}`
    }
    if (path) {
      cookieText += `; path=${path}`
    }
    if (domain) {
      cookieText += `; domain=${domain}`
    }
    if (secure) {
      cookieText += '; secure'
    }
    document.cookie = cookieText
  },
  unset(name, path, domain, secure) {
    this.set(name, null, new Date(0), path, domain, secure)
  },
  delete(name, path?:string, domain?:string) {
    this.set(name, null, -1, path, domain)
  },
}