import request from '@/utils/request/index';
// 获取语言列表
export const getLangListData = () =>
  request.get('/api/v1/translations/languages');
// 更改当前监管
export const changeRegulatorData = (regulator: string) =>
  request.post('/api/v1/regulators/current', { regulator });
// loginApi
export const to_login = (data: {}) =>
  request.post('/api/v1/auth/session/login', data);
//   return new Promise((resolve, reject) => {
//     request
//       .post('/api/v1/auth/session/login', data)
//       .then(res => {
//         resolve({
//           ...res,
//           data: {
//             ...res.data,
//             accessToken: {
//               loginAccount: 'zhangyao1',
//               token:
//                 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ6aGFuZ3lhbzEiLCJpYXQiOjE2ODA3Nzc0MzksImV4cCI6MTY4MDc3OTIzOX0.Q0gXPNNTUzFLCBCJy2jH5oZMsqW4HY_ibOsuS4-I0WWmJTGab18lHcLjucoNGq8EIbAJGjW0ftfC9qCE8Fm51g',
//               expirationTime: '2023-04-07 11:08:00',
//             },
//           },
//         });
//       })
//       .catch(err => reject(err));
//   });
// };

export const toLoginOut = () => request.post('/api/v1/auth/session/logout');

export const toShowPhoneOrEmail = params =>
  request.get('/api/v1/decrypt/email-or-phone', params);
// user info
export const getUserInfoData = () => request.post('/api/v1/user/getUserInfo');
// ringCentral login
/**
 *
 * @param {object} params
 * @param {string} params.state // redirect url
 * @returns
 */
export const ringCentralLogin = (params: { state: string }) =>
  request.get('/api/v1/communication/telephony/auth/login-url', params);
// get all enums
export const getAllEnumsData = () => request.get('/api/v1/enums/all');
// get Tags
export const getListTags = () => request.get('/api/v1/users/extends/tags');
